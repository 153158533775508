<template>
  <div class="pages">
    <div class="poster">
      <img src="./imgs/poster.png" alt="" />
    </div>
    <video
      :src="videoSrc"
      muted
      id="theVideo"
      @timeupdate="onPlayerTimeupdate($event)"
      @ended="onPlayerEnded($event)"
    ></video>
    <div class="time" v-show="dateShow">{{ date }}</div>
    <div class="stock_container" v-show="stockShow">
      <div class="container_header">
        <div class="left">股票名称</div>
        <div class="right">评分</div>
      </div>
      <div class="tbody">
        <ul class="stock_list1 none" id="stock_list1">
          <li
            :class="'li' + index"
            v-for="(item, index) in stockData1"
            :key="index"
          >
            <div class="left">
              <p class="stock_name">{{ item.name }}</p>
              <p class="stock_code">{{ item.code }}</p>
            </div>
            <div class="right">
              <div :class="'stock_grade' + index" :id="'stock_grade' + index">
                <span v-for="i in item.grade"
                  ><img class="star" src="./imgs/grade1.png" alt=""
                /></span>
                <span v-for="i in 5 - item.grade"
                  ><img class="star" src="./imgs/grade2.png" alt=""
                /></span>
              </div>
            </div>
          </li>
        </ul>
        <ul
          class="stock_list2 none"
          id="stock_list2"
          v-show="stockData2.length > 0"
        >
          <li
            :class="'li' + index"
            v-for="(item, index) in stockData2"
            :key="index"
          >
            <div class="left">
              <p class="stock_name">{{ item.name }}</p>
              <p class="stock_code">{{ item.code }}</p>
            </div>
            <div class="right">
              <div :class="'stock_grade' + index" :id="'stock_grade' + index">
                <span v-for="i in item.grade"
                  ><img class="star" src="./imgs/grade1.png" alt=""
                /></span>
                <span v-for="i in 5 - item.grade"
                  ><img class="star" src="./imgs/grade2.png" alt=""
                /></span>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="end_page" v-show="pageEndShow">
      <div class="log_box">
        <img class="logo" src="./imgs/logo.png" alt="" />
      </div>
      <div class="tips" id="tips">更多内容尽在灯影</div>
    </div>
  </div>
</template>

<script>
import { anyElement } from "@/components/anyElement.js";
import { switchPage } from "@/components/switchPage.js";
import { anyElementV1 } from "@/components/anyElement_1.js";
import { sleep, setRem, getUrlParams } from "@/utils/utils.js";
import $ from "jquery";
import FontFaceObserver from "fontfaceobserver";
import ApiServe from "@/api/api";
export default {
  data() {
    return {
      model_id: null,
      model_name: "",
      ip: "",
      port: "",
      audioData: [],
      audioLengthList: [],
      videoSrc: require("@/index/views/projects/shortLineSnipe/imgs/shortLineSnipe.mp4"),
      date: null,
      currentTime: 0,

      dateShow: false,
      dateTranslate: false,
      stockShow: false,
      pageEndShow: false,
      stockData: [],
      isPause: false,
    };
  },
  watch: {
    allReady(val) {
      if (val.audioData.length > 0) {
        if (!this.ip && !this.port) {
          console.log("没获取到ip,port");
          $(".poster").addClass("none");
          $("#theVideo")[0].play();
        } else {
          const params = {
            ip: this.ip,
            port: this.port,
            offset_x: this.offset_x,
            offset_y: this.offset_y,
            width: this.width,
            height: this.height,
          };
          ApiServe.recordStart(this.newIpPort, params)
            .then(({ code }) => {
              $(".poster").addClass("none");
              $("#theVideo")[0].play();
            })
            .catch((err) => {
              ApiServe.recordRelease(this.newIpPort, {
                ip: this.ip,
                port: this.port,
              });
            });
        }
      }
    },
    async dateShow(val) {
      if (val) {
        // setTimeout(() => {
        await sleep(0);
        $(".time").addClass("timeShow");
        // }, 0);
      }
    },
    dateTranslate(val) {
      if (val) {
        $(".time").addClass("translateY");
      }
    },
    async stockShow(val) {
      const that = this;
      if (val) {
        const m1 = new Map();
        $(".stock_list1").removeClass("none");
        for (let i = 0; i < this.stockData1.length; i++) {
          const anime = anyElement({
            targets: document.querySelector(`.stock_list1 .li${i}`),
            isSplitTxt: false,
            duration: 500,
            delay: 200,
            easing: "easeOutExpo",
            inEffect: "fadeIn",
            outEffect: "fadeOut",
          });
          const starAnime = anyElement({
            targets: document.querySelectorAll(`.stock_list1 .li${i} .star`),
            isSplitTxt: false,
            duration: 200,
            delay: 100,
            easing: "easeInExpo",
            inEffect: "rotateIn4",
            outEffect: "fadeOut",
          });
          m1.set(`li${i}`, anime);
          m1.set(`star${i}`, starAnime);
        }
        for (let i = 0; i < this.stockData1.length; i++) {
          await sleep(400);
          const anime = m1.get(`li${i}`);
          const starAnime = m1.get(`star${i}`);
          anime.runIn();
          starAnime.runIn();
        }
        await sleep(1500);

        if (this.stockData.length > 5) {
          const anime1 = anyElement({
            targets: document.querySelector(`.stock_list1`),
            isSplitTxt: false,
            duration: 500,
            delay: 500,
            easing: "easeOutExpo",
            inEffect: "fadeIn",
            outEffect: "fadeOut",
          });
          anime1.goOut.restart();
          await sleep(500);
          $(".stock_list1").addClass("none");

          $(".stock_list2").removeClass("none");
          const anime2 = anyElement({
            targets: document.querySelector(`.stock_list2`),
            isSplitTxt: false,
            duration: 500,
            delay: 500,
            easing: "easeOutExpo",
            inEffect: "fadeIn",
            outEffect: "fadeOut",
          });
          anime2.goIn.restart();

          const m2 = new Map();
          for (let i = 0; i < this.stockData2.length; i++) {
            const anime = anyElement({
              targets: document.querySelector(`.stock_list2 .li${i}`),
              isSplitTxt: false,
              duration: 500,
              delay: 200,
              easing: "easeOutExpo",
              inEffect: "fadeIn",
              outEffect: "fadeOut",
            });
            const starAnime = anyElement({
              targets: document.querySelectorAll(`.stock_list2 .li${i} .star`),
              isSplitTxt: false,
              duration: 200,
              delay: 100,
              easing: "easeInExpo",
              inEffect: "rotateIn4",
              outEffect: "fadeOut",
            });
            m2.set(`li${i}`, anime);
            m2.set(`star${i}`, starAnime);
          }
          for (let i = 0; i < this.stockData2.length; i++) {
            await sleep(500);
            const anime = m2.get(`li${i}`);
            const starAnime = m2.get(`star${i}`);
            anime.runIn();
            starAnime.runIn();
          }
        }
      }
    },
    async pageEndShow(val) {
      if (val) {
        const logoAnime = anyElement({
          targets: document.querySelector(`.logo`),
          isSplitTxt: false,
          duration: 200,
          delay: 100,
          easing: "easeInExpo",
          inEffect: "fadeIn",
          outEffect: "fadeOut",
        });
        const tipsAnime = anyElement({
          targets: document.querySelector(`.tips`),
          isSplitTxt: true,
          duration: 500,
          delay: 100,
          easing: "easeInExpo",
          inEffect: "fadeIn",
          outEffect: "fadeOut",
        });
        $(".stock_container").css({
          opacity: 0,
          transition: "0.5s",
        });
        await sleep(500);
        this.stockShow = false;
        logoAnime.goIn.restart();
        await sleep(200);
        tipsAnime.goIn.restart();
      }
    },
  },
  computed: {
    stockData1() {
      return this.stockData.length > 5
        ? this.stockData.slice(0, 5)
        : this.stockData;
    },
    stockData2() {
      return this.stockData.length > 5 ? this.stockData.slice(5) : [];
    },
    allReady() {
      const { dataTime, audioData } = this;
      return {
        dataTime,
        audioData,
      };
    },
    newIpPort() {
      if (this.source == "ali") {
        return "https://recordvideoali.wedengta.com";
      } else {
        return "https://recordvideo.wedengta.com";
      }
    },
  },
  components: {},
  methods: {
    getTime() {
      const date = new Date();
      const y = date.getFullYear();
      const m =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      const d = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      this.date = y + "." + m + "." + d;
    },

    async fontLoad() {
      const harmonyMidium = new FontFaceObserver("harmonyMidium");
      const harmonyBold = new FontFaceObserver("harmonyBold");
      await Promise.all([
        harmonyMidium.load(null, 60 * 1000),
        harmonyBold.load(null, 60 * 1000),
      ]);
      console.log("字体加载完成");
    },

    onPlayerTimeupdate(player) {
      const currentTime = player.target.currentTime;
      this.currentTime = currentTime;
      //   console.log(currentTime);
      if (currentTime >= 4 && currentTime < 7.7) {
        this.dateShow = true;
      } else if (currentTime >= 7.7 && currentTime < 9) {
        this.dateTranslate = true;
      } else if (currentTime >= 9 && currentTime < 17) {
        this.stockShow = true;
      } else if (currentTime >= 17) {
        this.pageEndShow = true;
      }
    },

    onPlayerEnded(e) {
      console.log("播放结束");
      this.endRecord();
    },

    async getData() {
      const that = this;
      let params = {
        model_id: this.model_id,
      };
      if (this.url_extra_params) {
        params = {
          model_id: this.model_id,
          url_extra_params: this.url_extra_params,
        };
      }
      await ApiServe.getData({ model_id: this.model_id })
        .then((res) => {
          if (res.data.code == 200) {
            let data = res.data.data;

            const stockData = data.chart_data_1[0].data;
            console.log(stockData);
            // const arr = Object.entries()
            this.stockData = stockData.secuabbr.map((item, index) => {
              return {
                name: item,
                code: stockData.secucode[index],
                grade: stockData.star[index].length,
              };
            });
            if (this.ip) {
              ApiServe.releasePcPort({ ip: this.ip, port: this.port });
            }
          }
        })
        .catch((err) => {
          if (this.ip) {
            ApiServe.releasePcPort({ ip: this.ip, port: this.port });
          }
        });
    },

    async getAudio() {
      let params = {
        model_id: this.model_id,
      };
      if (this.url_extra_params) {
        params = {
          model_id: this.model_id,
          url_extra_params: this.url_extra_params,
        };
      }
      await ApiServe.getVideoData(params)
        .then(({ data: { data, code } }) => {
          console.log(data, code);
          if (code == 200) {
            this.audioData = data;

            if (this.ip && this.audioData.length == 0) {
              ApiServe.recordRelease({ ip: this.ip, port: this.port });
            }
          }
        })
        .catch((err) => {
          if (this.ip) {
            ApiServe.releasePcPort({ ip: this.ip, port: this.port });
          }
        });
    },

    async endRecord() {
      let arr = [];

      let audiosOrderList = this.audioData.map((item, index) => {
        return {
          audioLink: item.audio_link,
          timeIndex: 1,
          audioLen: item.audio_length,
        };
      });

      const data = {
        id: this.id,
        model_id: this.model_id,
        videoTotalTime: 20,
        videmBgm:
          "https://idtcdn.oss-cn-hangzhou.aliyuncs.com/Cube/VideoBgm/bg.mp3",
        audiosOrderList: audiosOrderList,
      };
      await ApiServe.recordStop(this.newIpPort, {
        ip: this.ip,
        port: this.port,
      })
        .then(() => {
          ApiServe.combineAudiosWithBgm(
            this.newIpPort,
            this.ip,
            this.port,
            data
          )
            .then(() => {
              ApiServe.recordRelease(this.newIpPort, {
                ip: this.ip,
                port: this.port,
              });
            })
            .catch(() => {
              ApiServe.recordRelease(this.newIpPort, {
                ip: this.ip,
                port: this.port,
              });
            });
        })
        .catch(() => {
          ApiServe.recordRelease(this.newIpPort, {
            ip: this.ip,
            port: this.port,
          });
        });
    },
  },
  async mounted() {
    const that = this;
    this.model_id = 72;
    this.id = getUrlParams().id || null;
    this.model_name = getUrlParams().model_name || null;
    this.model_category = getUrlParams().model_category || null;
    this.url_extra_params = getUrlParams().url_extra_params || null;
    this.ip = getUrlParams().ip || null;
    this.port = getUrlParams().port || null;
    this.offset_x = getUrlParams().offset_x || null;
    this.offset_y = getUrlParams().offset_y || null;
    this.width = getUrlParams().width || null;
    this.height = getUrlParams().height || null;
    this.source = getUrlParams().source || null;
    //每个项目都可以独立设置
    setRem(720);

    this.getTime();

    //方便开发的时候暂停视频
    $(document).keydown(function (event) {
      if (event.keyCode == 32 && that.isPause) {
        $("#theVideo")[0].play();
      } else if (event.keyCode == 32 && !that.isPause) {
        $("#theVideo")[0].pause();
      }
      that.isPause = !that.isPause;
    });

    //字体加载比较慢，等它加载完
    await this.fontLoad();
    await this.getData();
    await this.getAudio();
    // $("#theVideo")[0].play();
  },
};
</script>

<style lang="less" scoped>
@import "index.less";
</style>